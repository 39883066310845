import React from 'react';
import { IconGrid } from '../../components/cst/IconGrid';
import { extractRepeaterField } from '../../util/blocks';

function ACFSingleColumnGrid({ className, anchor, data, pageProps }) {
    const { top_heading, main_heading, block_anchor } = data;
    let items = extractRepeaterField(data, 'grid');
    items = items.map((repeaterItem) => ({
        ...repeaterItem,
        image_icon: pageProps.mediaItems[repeaterItem.image_icon] || {}
    }));
    const cta = extractRepeaterField(data, 'cta');
    // const imageFile = pageProps.mediaItems[image] || {};

    return (
        <>
            <IconGrid
                sectionTitle={top_heading}
                title={main_heading}
                cta={cta}
                items={items}
                className={className}
                anchor={block_anchor}
            />
        </>
    );
}

export default ACFSingleColumnGrid;
