import React, { useState } from 'react';

export const ContactForm = () => {
    const [messageType, setMessageType] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    // const [contactPhone, setContactPhone] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmited, setIsSubmited] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const request = new Request('/api/contact', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                message_type: messageType,
                name: contactName,
                from: contactEmail,
                phone: 'N/A',
                message: message
            })
        });

        fetch(request)
            .then(function (response) {
                if (response.status === 200) {
                    setMessageType('');
                    setContactName('');
                    setContactEmail('');
                    // setContactPhone('');
                    setMessage('');
                    setIsSubmited(true);
                    setTimeout(() => {
                        setIsSubmited(false);
                    }, 3500);
                } else {
                    console.log(response);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };
    return (
        <>
            {isSubmited && (
                <div className="p-8 text-center bg-light">
                    <img
                        src="/images/check.svg"
                        alt=""
                        className="block mx-auto w-11"
                    />
                    <p className="font-semibold text-20 text-primary-500">
                        Thank you for filling out your information!
                    </p>
                </div>
            )}
            {!isSubmited && (
                <form name="contact" method="post" onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <select
                            name="message_type"
                            className="w-full border-2 border-gray-700"
                            value={messageType}
                            required
                            onChange={(event) =>
                                setMessageType(event.target.value)
                            }
                        >
                            <option>Select...</option>
                            <option value="I have a question about the EpiSwitch CST">
                                I have a question about the EpiSwitch CST
                            </option>
                            <option value="I need help finding an EpiSwitch CST provider">
                                I need help finding an EpiSwitch CST provider
                            </option>
                            <option value="I would like an EpiSwitch CST rep to contact me">
                                I would like an EpiSwitch CST rep to contact me
                            </option>
                            <option value="I have a medical question about the EpiSwitch CST">
                                I have a medical question about the EpiSwitch
                                CST
                            </option>
                            <option value="I would like to become an EpiSwitch CST healthcare provider.">
                                I would like to become an EpiSwitch CST
                                healthcare provider.
                            </option>
                            <option value="Other…">Other…</option>
                            <option value="Feedback…">Feedback…</option>
                        </select>
                    </div>
                    <div className="mb-6">
                        <p className="mb-2 text-gray-700">
                            Name <span className="text-red">*</span>
                        </p>
                        <input
                            className="w-full border-2 border-gray-700"
                            type="text"
                            name="name"
                            value={contactName}
                            required
                            onChange={(event) =>
                                setContactName(event.target.value)
                            }
                        />
                    </div>
                    <div className="mb-6">
                        <p className="mb-2 text-gray-700">
                            Email <span className="text-red">*</span>
                        </p>
                        <input
                            className="w-full border-2 border-gray-700"
                            type="email"
                            name="from"
                            required
                            value={contactEmail}
                            onChange={(event) =>
                                setContactEmail(event.target.value)
                            }
                        />
                    </div>
                    {/* <div className="mb-6">
                        <p className="mb-2 text-gray-700">
                            Phone <span className="text-red">*</span>
                        </p>
                        <input
                            className="w-full border-2 border-gray-700"
                            type="text"
                            name="phone"
                            value={contactPhone}
                            required
                            onChange={(event) =>
                                setContactPhone(event.target.value)
                            }
                        />
                    </div>  */}
                    <div className="mb-6">
                        <p className="mb-2 text-gray-700">
                            Message <span className="text-red">*</span>
                        </p>
                        <textarea
                            className="w-full border-2 border-gray-700"
                            name="message"
                            rows={7}
                            value={message}
                            required
                            onChange={(event) => setMessage(event.target.value)}
                        ></textarea>
                    </div>
                    <div className="text-right">
                        <button
                            type="submit"
                            className={`inline-block px-6 py-2 font-semibold text-white uppercase transition bg-primary-500 text-20 rounded-30 hover:bg-primary hover:text-white ${
                                isLoading
                                    ? 'pointer-events-none opacity-60'
                                    : ''
                            } `}
                        >
                            {isLoading && 'Submiting...'}
                            {!isLoading && 'Submit'}
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};
