import React from 'react';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
const Plus = ({}) => {
    return <img src="/images/plus.png" />;
};
const Minus = ({}) => {
    return <img src="/images/minus.png" />;
};
export const Faq = ({ anchor = '', className = '', title, faqs = [] }) => {
    return (
        <div className={`py-10 bg-white" ${className}`} id={anchor}>
            <div className="container">
                {title && (
                    <h3
                        className="mb-10 font-medium text-20"
                        dangerouslySetInnerHTML={{
                            __html: title
                        }}
                    />
                )}
                <div className="">
                    <Accordion
                        className="flex flex-wrap -m-4"
                        allowZeroExpanded
                    >
                        {faqs.map((faq, i) => (
                            <div className="w-full px-4 mb-8 md:w-2/4">
                                <AccordionItem
                                    key={i}
                                    className={`border-3 border-primary-500 `}
                                >
                                    <AccordionItemHeading>
                                        <AccordionItemButton className="flex items-center px-4 py-3 font-semibold outline-none text-20 text-primary-500 leading-25">
                                            <AccordionItemState>
                                                {({ expanded }) =>
                                                    expanded ? (
                                                        <Minus />
                                                    ) : (
                                                        <Plus />
                                                    )
                                                }
                                            </AccordionItemState>
                                            <span className="ml-3">
                                                {faq.title}
                                            </span>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: faq.text_area
                                            }}
                                            className="px-4 py-3 font-medium text-16 leading-26"
                                        />
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </div>
                        ))}
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

Faq.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    icon1: PropTypes.string
};
