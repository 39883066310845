import React from 'react';
import { Faq } from '../../components/cst/Faq';
import { extractRepeaterField } from '../../util/blocks';

function ACFFAQ({ className, anchor, data, pageProps }) {
    const { top_heading, block_anchor } = data;

    let faqs = extractRepeaterField(data, 'faq_items');

    return (
        <>
            <Faq
                title={top_heading}
                faqs={faqs}
                className={className}
                anchor={block_anchor}
            />
        </>
    );
}

export default ACFFAQ;
