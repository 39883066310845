import Link from 'next/link';
import { useRouter } from 'next/router';

/*
 * This is a utility class used to inject active class into a Bootstrap
 * nav link for initial prototype: may be reusable elsewhere.
 */
const NextNavLink = ({ href, target, children, passHref, activeClassName }) => {
    const router = useRouter();

    let className = children.props.className || '';

    if (router.asPath.split('#')[0] === href.split('#')[0]) {
        className = `${className} ${activeClassName ? activeClassName : ''}`;
    }

    const voidLink = href && href.startsWith('#');

    return voidLink ? (
        React.cloneElement(children, { className, href, target })
    ) : (
        <Link href={href} passHref={passHref}>
            {React.cloneElement(children, { className, target })}
        </Link>
    );
};

export default NextNavLink;
