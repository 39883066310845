import React from 'react';

import { Image } from '../../util/image';

function CoreImage({ width, height, className, sizeSlug, url, ...props }) {
    if (!width || !height) {
        return <Image src={url} className={className} alt={props.alt} />;
    }
    return (
        <Image
            src={url}
            width={width || sizeSlug === 'large' ? '100%' : ''}
            height={height}
            className={className}
            alt={props.alt}
        />
    );
}

export default CoreImage;
