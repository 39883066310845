import React from 'react';

export const ImageText = ({
    title,
    content,
    image,
    className,
    anchor = '',
    align = 'center',
    vAlign = 'center'
}) => {
    return (
        <div
            id={anchor}
            className={`relative flex items-${
                vAlign === 'bottom' ? 'end' : ''
            } bg-cover ${vAlign === 'bottom' ? 'py-72 pb-0' : 'py-60 pb-0'}  ${
                className ? className : ''
            }`}
            style={{
                backgroundImage: `url(${image.mediaItemUrl || image})`
            }}
        >
            <div
                className={`container bg-cover ${
                    vAlign === 'bottom' ? 'lg:mb-14 lg:mt-10' : 'lg:mb-24'
                }  ${className ? className : ''}`}
            >
                <div className={`flex items-center justify-center `}>
                    <div className="w-full lg:w-2/4 md:w-9/12 lg:px-6">
                        <h3
                            className="mb-2 font-medium text-20 leading-30"
                            dangerouslySetInnerHTML={{
                                __html: title
                            }}
                        />
                        <p
                            className="font-bold text-25 leading-33 text-black"
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
