import React from 'react';

/* WordPress Core Gutenberg blocks */
import CoreColumn from '../blocks/core/column';
import CoreColumns from '../blocks/core/columns';
import CoreGroup from '../blocks/core/group';
import CoreImage from '../blocks/core/image';
import CoreParagraph from '../blocks/core/paragraph';
import CoreHeading from '../blocks/core/heading';

/* ACF Blocks */
import HeroBlock from '../blocks/acf/hero-block';
import SingleColumnGrid from '../blocks/acf/single-column-grid';
import TwoColumnGrid from '../blocks/acf/two-column-grid';
import TwoColumn from '../blocks/acf/two-column';
import ImageText from '../blocks/acf/image-with-text';
import Faq from '../blocks/acf/faq';
import ContactBlock from '../blocks/acf/contact';

export const blockNameToComponent = {
    'acf/hero-banner-block': HeroBlock,
    'acf/two-column': TwoColumn,
    'acf/two-column-grid': TwoColumnGrid,
    'acf/single-column-grid': SingleColumnGrid,
    'acf/image-with-text': ImageText,
    'acf/faq-block': Faq,
    'acf/contact-block': ContactBlock,

    'core/column': CoreColumn,
    'core/columns': CoreColumns,
    'core/group': CoreGroup,
    'core/image': CoreImage,
    'core/paragraph': CoreParagraph,
    'core/heading': CoreHeading
};

export function renderGutenbergBlocks(blocks, pageProps = {}) {
    return blocks.map((block, index) => {
        const BlockComponent = blockNameToComponent[block.name];
        if (!BlockComponent) {
            /* Tried to render a block without a local equivalent */
            return null;
        }
        return (
            <BlockComponent
                key={index}
                {...block.attributes}
                pageProps={pageProps}
            >
                {renderGutenbergBlocks(block.innerBlocks, pageProps)}
            </BlockComponent>
        );
    });
}

/* Utility function to reconstruct repeater fields' array client-side */
export function extractRepeaterField(data, fieldName) {
    if (!fieldName in data) {
        return null;
    }

    const topItem = data[fieldName];
    const dataKeys = Object.keys(data);
    const results = [];

    for (let i = 0; i < topItem; i++) {
        const re = new RegExp(`^${fieldName}_${i}_(.*)$`);
        const fields = {};

        for (const dataKey of dataKeys) {
            const matches = re.exec(dataKey);

            if (matches) {
                const [unmodifiedKey, fieldKey] = matches;

                fields[fieldKey] = data[unmodifiedKey];
            }
        }

        results.push(fields);
    }

    return results;
}
