import React from 'react';

import MainHeader from '../MainHeader';
import TopHeader from '../TopHeader';

export default function Header(props) {
    const { menus } = props;
    let menuItems = [];

    if (menus['header-menu']) {
        menuItems = menus['header-menu'].menuItems;
    }

    const matchingMenus = menuItems
        .filter(menuItem => menuItem.path === props.uri);
    const subMenuItems = matchingMenus.length
        ? matchingMenus[0].children
        : [];

    return (
        <header>
            <TopHeader menuItems={menuItems} />
            <MainHeader menuItems={subMenuItems} />
        </header>
    );
}
