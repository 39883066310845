import React, { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

import NextNavLink from '../../util/NextNavLink';

import { useDebounce, useClickOutside } from '../../../util/hooks';

/* An example Tailwind UI menu supporting sub-items as dropdowns */
function MainHeader({ menuItems }) {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const debouncedQuery = useDebounce(searchQuery, 500);

    const ref = useRef();
    const searchRef = useRef();
    useEffect(async () => {
        if (debouncedQuery && debouncedQuery.length > 2) {
            const resp = await fetch(
                '/api/search?' +
                    new URLSearchParams({
                        q: searchQuery
                    })
            );
            const json = await resp.json();

            if (json.response !== 'SUCCESS') {
                setSearchResults([]);
                setShowSearchResults(false);

                return;
            }

            setSearchResults(json.results);
            setShowSearchResults(true);
        } else {
            setSearchResults([]);
            setShowSearchResults(false);
        }
    }, [debouncedQuery]);

    function resetSearch() {
        setShowSearchResults(false);
        setSearchQuery('');
    }

    useClickOutside(ref, () => setShowMobileMenu(false));
    useClickOutside(searchRef, () => setShowSearchResults(false));
    
    let getPathOrUrl = (menuItem) => {
        return menuItem.url.includes('wp-content') ? menuItem.url : menuItem.path;
    }

    return (
        <div className="relative w-full max-w-screen-xl px-4 mx-auto bg-white">
            <div className="flex items-center justify-between py-6 ">
                <div>
                    <NextNavLink href="/">
                        <a className="flex">
                            <img
                                className="w-auto h-6 xl:h-8"
                                src="/images/logo.svg"
                                alt="EpiSwitch CST Logo"
                            />
                        </a>
                    </NextNavLink>
                </div>
                <div className="-my-2 -mr-2 md:hidden">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setShowMobileMenu(!showMobileMenu)}
                    >
                        <span className="sr-only">Open menu</span>
                        <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                </div>
                <div className="justify-end hidden md:flex">
                    <nav className="flex xl:gap-8 lg:gap-6 md:gap-4 ">
                        {menuItems.map((menuItem, index) => (
                            <NextNavLink
                                key={index}
                                href={menuItem.url}
                                target={menuItem.target}
                            >
                                <a className="font-medium xl:text-20 lg:text-16 md:text-14 text-primary leading-21 hover:text-secondary ">
                                    {menuItem.title}
                                </a>
                            </NextNavLink>
                        ))}
                    </nav>
                </div>
            </div>

            <Transition
                show={showMobileMenu}
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                className="absolute left-0 w-full border-b z-1"
            >
                <div className="flex flex-col w-full gap-4 pb-6 bg-white">
                    {menuItems.map((menuItem, index) => (
                        <NextNavLink
                            key={index}
                            href={getPathOrUrl(menuItem)}
                            target={menuItem.target}
                        >
                            <a
                                onClick={() => setShowMobileMenu(false)}
                                className="px-4 pt-4 font-medium border-t text-16 text-primary leading-21 hover:text-secondary"
                            >
                                {menuItem.title}
                            </a>
                        </NextNavLink>
                    ))}
                </div>
            </Transition>
        </div>
    );
}

export default MainHeader;
