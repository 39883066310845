import React, { useEffect, useRef, useState } from 'react';

import NextNavLink from '../../util/NextNavLink';
import { useRouter } from 'next/router';
function TopHeader({ menuItems }) {

    let router = useRouter();
    
    let isActive = (path) => {
        let activeClass = 'text-secondary lg:border-b-4 border-b-2 border-secondary'
        
        // Set the home page link as active if it we are on the about patients page 
        if (router.asPath === '/about-patients/' && path === '/') {
            return activeClass;

            // Set the cst for providers page link as active if it we are on the about providers page 
        } else if (router.asPath === '/about-providers/' && path === '/cst-for-providers/') {
            return activeClass;
            
            // Set the link as active if the path matches the router path - this is for the parent pages
        } else if (router.asPath === path) {
            return activeClass;
            
            // Set the link as active if the path matches the router path once anchors are removed
        } else if (router.asPath.split('#')[0] === path) {
            return activeClass;
        } else {
            
            // do not set it as active if it does not match any of the other conditions
            return '';
        }
        
        
    }

    return (
        <div className="py-2.5 bg-primary-light">
            <div className="container">
                <div className="flex justify-end font-medium">
                    {menuItems.map((item, i) => (
                        <span
                            className={` ${
                                i + 1 === menuItems.length
                                    ? 'pl-1 sm:pl-6'
                                    : 'px-1 sm:px-6 border-r border-primary'
                            }`}
                        >
                            <NextNavLink
                                // activeClassName="text-secondary lg:border-b-4 border-b-2 border-secondary"
                                key={i}
                                href={item.path || item.url}
                            >
                                <a
                                    className={` transition md:text-21 sm:text-14 text-12 text-primary leading-21 hover:text-secondary border-primary ${isActive(item.path)}`}
                                >
                                    {item.title}
                                </a>
                            </NextNavLink>
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TopHeader;
