import { NextSeo } from 'next-seo';

import { getMediaItems } from '../lib/media';
import { getMenus } from '../lib/menus';
import { getOptions } from '../lib/option';
import { getPage, getPages } from '../lib/pages';

import { renderGutenbergBlocks } from '../util/blocks';
import { renderShortcodes } from '../util/shortcodes';

import Post from '../layout/Post';

export default function Page(props) {
    const { shortcodes, blocks, seo } = props;
    const { canonical, title, metaDesc } = seo;

    let fixedCanonical = canonical;
    if (canonical.includes('cms.covidseveritytest.com')) {
        fixedCanonical = canonical.replace("cms.covidseveritytest.com", "www.covidseveritytest.com");
    }


    return (
        <Post pageProps={props}>
            <NextSeo
                canonical={fixedCanonical}
                title={title}
                description={metaDesc}
            />

            {renderGutenbergBlocks(blocks, props)}
            {renderShortcodes(shortcodes, props)}
        </Post>
    );
}

export async function getStaticPaths(context) {
    const paths = (await getPages()).map((page) => page.uri);

    return { paths, fallback: false };
}

export async function getStaticProps(context) {
    let { page = '/' } = context.params;
    if (context?.params?.page && Array.isArray(context.params.page)) {
        page = context.params.page[0];
    }

    const pageData = await getPage(page);

    const {
        title,
        blocksJSON,
        uri,

        contentType: {
            node: { name: contentType }
        },
        seo
    } = pageData.contentNode;
    const blocks = JSON.parse(blocksJSON);

    /*
     * Retrieve media items and map to an object keying post_id to the media item's properties
     */
    const menus = {};

    (await getMenus()).map((menu) => (menus[menu.slug] = menu));

    const options = await getOptions();

    /*
     * Retrieve media items and map to an object keying post_id to the media item's properties
     */
    const mediaItems = {};

    (await getMediaItems()).map(
        (mediaItem) => (mediaItems[mediaItem.mediaItemId] = mediaItem)
    );

    return {
        props: {
            title,
            blocks,
            menus,
            uri,
            mediaItems,
            contentType,
            seo,
            options
        }
    };
}
