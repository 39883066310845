import React from 'react';

import Header from '../components/cst/Header';
import Footer from '../components/cst/Footer';

const Post = ({ pageProps = {}, children }) => {
    return (
        <>
            <Header {...pageProps} />

            <main className="">{children}</main>

            <Footer {...pageProps} />
        </>
    );
};

export default Post;
