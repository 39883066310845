import React from 'react';
import PropTypes from 'prop-types';

export const TwoColumnGrid = ({
    className,
    anchor,
    sectionTitle,
    title,
    cta,
    items = []
}) => {
    return (
        <div
            className={`grid items-center py-20 ${className ? className : ''}`}
            id={anchor ? anchor : ''}
        >
            <div className="container text-center md:text-left">
                <h2
                    className="mb-1 font-medium text-20 leading-25"
                    dangerouslySetInnerHTML={{
                        __html: sectionTitle
                    }}
                />
                <div
                    className="font-bold mb-14 text-25 leading-31 text-primary-500"
                    dangerouslySetInnerHTML={{
                        __html: title
                    }}
                />
                <div className={`md:grid block gap-8 md:grid-cols-12`}>
                    {items.map((item, i) => (
                        <div
                            key={i}
                            className={`md:col-span-6 text-center lg:px-10 md:mb-10 mb-8`}
                        >
                            <img
                                src={
                                    item.image_icon.mediaItemUrl ||
                                    item.image_icon
                                }
                                alt={item.image_icon.altText}
                                className="block mx-auto mb-6 max-h-20"
                            />
                            <h4
                                className="mb-3 font-bold uppercase text-17 leading-20"
                                dangerouslySetInnerHTML={{
                                    __html: item.heading
                                }}
                            />
                            <div
                                className="font-medium text-17 leading-30"
                                dangerouslySetInnerHTML={{
                                    __html: item.text_area
                                }}
                            />
                            {item.cta && (
                                <div className="mt-4 text-center">
                                    <a
                                        target={item.cta.target}
                                        href={item.cta.url}
                                        className="inline-block px-6 py-2 font-semibold text-white uppercase transition bg-primary-500 text-20 rounded-30 hover:bg-primary hover:text-white"
                                    >
                                        {item.cta.title}
                                    </a>
                                </div>
                            )}
                            {item.cta_2 && (
                                <div className="mt-4 text-center">
                                    <a
                                        target={item.cta_2.target}
                                        href={item.cta_2.url}
                                        className="inline-block px-6 py-2 font-semibold text-white uppercase transition bg-primary-500 text-20 rounded-30 hover:bg-primary hover:text-white"
                                    >
                                        {item.cta_2.title}
                                    </a>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {cta && (
                    <div className="mt-10 text-center">
                        {cta.map((cta, i) => (
                            <a
                                key={i}
                                target={cta.link.target}
                                href={cta.link.url}
                                className="inline-block px-6 py-2 font-semibold text-white uppercase transition bg-primary-500 text-20 rounded-30 hover:bg-primary hover:text-white"
                            >
                                {cta.link.title}
                            </a>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
